@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Unica+One&display=swap);
body {
  background: rgb(21, 21, 21);
  font-family: "Unica One", cursive;
}

a {
  text-decoration: none;
  color: black;
}

a:visited {
  color: black;
}

